<template>
  <v-app :style="colorVapp">
    <onebox_admin_toolbar
      :quicksearch="true"
      @loaddata="loaddata()"
      @loadfile="loadfolder()"
      @callstorage="loadstorage()"
      @closeDrag="removeEvent()"
      @openDrag="addEvent()"
    ></onebox_admin_toolbar>
    <v-content>
      <v-card class="elevation-0">
        <v-divider></v-divider>
        <v-overlay :value="processloader" absolute :color="color.BG" dark>
          <v-progress-circular indeterminate size="64" :color="color.theme"></v-progress-circular>
          <span :style="colorProgress">&nbsp; loading</span>
        </v-overlay>

        <!-- hearder -->
        <v-list nav :color="color.BG">
          <v-list-item class="text-left">
            <v-list-item-avatar class="text-center">
              <v-avatar :color="color.theme" size="35">
                <v-icon dark size="20">mdi-file-document-edit</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <!-- <span :style="headerPage">&nbsp;{{ $t("admintoolbar.consent") }}</span> -->
                <span :style="headerPage">&nbsp;{{ $t("admintoolbar.setbusiness") }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card class="elevation-0" :color="color.BG" id="thiscontainersetemailadmin">
          <v-card-text class="pa-0">
            <v-container fluid>
              <v-layout>
                <v-flex class="text-right">
                  <!-- <v-btn rounded dark :color="color.theme" @click="btnConsent()">{{ $t("consentlog") }}</v-btn> -->
                  <v-btn rounded dark :color="color.theme" @click="btnConsent()">{{ $t("setbusinesslog") }}</v-btn>
                </v-flex>
              </v-layout>
              <v-layout row wrap justify-center fill-height>
                <v-flex lg12 class="ma-2 ml-4 mr-4" v-if="loaddataprogress === true">
                  <div class="text-center">
                    <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                  </div>
                </v-flex>
                <v-flex lg12 class="ma-4" v-else>
                  <div v-if="loaddataprogresstable === true">
                    <v-flex lg12 class="ma-2 ml-4 mr-4">
                      <div class="text-center">
                        <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                      </div>
                    </v-flex>
                  </div>
                  <div v-else>
                    <!-- mobile -->
                    <div v-if="resolutionScreen < 500">
                      <v-list two-line class="mb-6 pb-6 elevation-0" v-if="listconsent.length !== 0">
                        <v-list-item v-for="item in listconsent" :key="item.title" style="cursor: pointer">
                          <v-list-item-content>
                            <v-list-item-title v-if="$t('default') === 'th'" v-text="item.consent.consent_header"></v-list-item-title>
                            <v-list-item-title v-else>Consent for collection and use or Reveal personal information</v-list-item-title>
                            <v-list-item-subtitle v-text="item.status"></v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn
                              class="elevation-0"
                              fab
                              small
                              @click="adminpolicy(item)"
                            >
                              <v-icon>mdi-information</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                      <v-list v-else class="mb-6 pb-6 elevation-0">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="text-center" v-text="$t('tablefile.empty')"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <div>
                        <v-list class="mb-6 pb-6 elevation-0">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title v-text="$t('consent.title_log')"></v-list-item-title>
                            <v-layout>
                              <p class="mr-1" style="color:rgba(0,0,0,.6); margin-top:1px;">{{$t('consent.Status')+':'+" "}}</p>
                            <v-list-item-subtitle v-text="name_current_status === '0' ? $t('consent.receive_all_with_except') : name_current_status === '1' ? $t('consent.receive_specificate') : $t('consent.receive_all')"></v-list-item-subtitle>
                            </v-layout>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn
                              class="elevation-0"
                              fab
                              small
                              @click="consent_etax_status()"
                            >
                              <v-icon>mdi-information</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                      </div>
                      <!-- ตั้งการรีเซ็ตรหัสผ่าน -->
                      <div v-if="feature_password_expired === true">
                        <v-list class="mb-6 pb-6 elevation-0">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title v-text="$t('admin.password_expired')"></v-list-item-title>
                            <v-list-item-subtitle v-text="expired_day === '0' || expired_day === 0 ? $t('admin.no_date_set') : expired_day + ' ' + $t('admin.day')"></v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn
                              class="elevation-0"
                              fab
                              small
                              @click="setting_reset_password()"
                            >
                              <v-icon>mdi-information</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                      </div>
                      <div v-if="feature_two_factor_authen === true">
                        <v-list class="mb-6 pb-6 elevation-0">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title v-text="$t('admin.two_factor_authen')"></v-list-item-title>
                            <v-list-item-subtitle v-text="list_two_factor_authen.current_status === 'Y' ? $t('admin.enable') : $t('admin.disable')"></v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn
                              class="elevation-0"
                              fab
                              small
                              @click="setting_two_factor_authen()"
                            >
                              <v-icon>mdi-information</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                      </div>
                    </div>
                    <!-- desktop -->
                    <div v-else>
                      <v-data-table
                        :headers="header"
                        :items="listconsent"
                        class="elevation-0"
                        :no-data-text="$t('tablefile.empty')"
                        :page.sync="page"
                        :items-per-page="size"
                        :hide-default-footer="true"
                        @page-count="pageCount = $event"
                      >
                        <template v-slot:[`header.type`]="{ header }">
                          <span :style="headerTable">{{$t(header.text)}}</span>
                        </template>
                        <template v-slot:[`header.consent_header`]="{ header }">
                          <span :style="headerTable">{{$t(header.text)}}</span>
                        </template>
                        <template v-slot:[`header.status`]="{ header }">
                          <span :style="headerTable">{{$t(header.text)}}</span>
                        </template>

                        <template v-slot:item="props">
                          <!-- ความยินยอม -->
                          <tr class="pointer" @click="adminpolicy(props.item)">
                            <td width="7%" class="text-center">
                              <v-icon>mdi-file-document-edit</v-icon>
                            </td>
                            <td v-if="$t('default') === 'th'" width="80%">{{ props.item.consent.consent_header }}</td>
                            <td v-else width="80%">{{ "Consent for collection and use or Reveal personal information" }}</td>
                            <td width="13%">{{ props.item.status }}</td>
                          </tr>
                          <!-- log ข้อมูลการใช้งาน จำกัดการรับ e-tax -->
                          <tr class="pointer" style="cursor:pointer;" @click="consent_etax_status()">
                            <td width="7%" class="text-center">
                              <v-icon>mdi-email-edit</v-icon>
                            </td>
                            <!-- ข้อมูลการใช้งาน จำกัดการรับ e-tax -->
                            <td width="80%">{{$t("consent.title_log")}}</td>
                            <!-- ชื่อสถานะจำกัด e-tax รับทั้งหมด(2),รับเฉพาะ(1),ยกเว้น(0) -->
                            <!-- <td width="13%">{{ listconsent_etax_log}}</td> -->
                            <td width="13%">{{name_current_status === '0' ? $t("consent.receive_all_with_except") : name_current_status === '1' ? $t("consent.receive_specificate") : $t("consent.receive_all") }}</td>
                          </tr>
                          <!-- ตั้งการรีเซ็ตรหัสผ่าน -->
                          <tr v-if="feature_password_expired === true" class="pointer" style="cursor:pointer;" @click="setting_reset_password()">
                            <td width="7%" class="text-center">
                              <v-icon>mdi-lock-reset</v-icon>
                            </td>
                            <td width="80%">{{$t("admin.password_expired")}}</td>
                            <td width="13%">{{ expired_day === '0' || expired_day === 0 ? $t('admin.no_date_set') : expired_day + ' ' + $t("admin.day")}}</td>
                          </tr>
                          <tr v-if="feature_two_factor_authen === true" class="pointer" style="cursor:pointer;" @click="setting_two_factor_authen()">
                            <td width="7%" class="text-center">
                              <v-icon>mdi-cellphone-lock</v-icon>
                            </td>
                            <td width="80%">{{$t("admin.two_factor_authen")}}</td>
                            <td width="13%">{{ list_two_factor_authen.current_status === 'Y' ? $t('admin.enable') : $t('admin.disable')}}</td>
                          </tr>
                        </template>
                      </v-data-table>
                    </div>
                    <br />
                    <v-layout row wrap justify-center>
                      <v-flex xs11 lg11 v-if="pageCount > 0">
                        <v-pagination
                          :total-visible="10"
                          v-model="page"
                          :length="pageCount || 0"
                          :color="color.theme"
                          @input="clickpagination()"
                        ></v-pagination>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>

            <dialogcensentlog
            :show="opendialogcensentlog"
            @close="opendialogcensentlog = false"
            >
            </dialogcensentlog>
            <dialogadminpolicy
              :show="opendialogadminpolicy"
              :datapolicy="selectedpolicy"
              @close="opendialogadminpolicy = false" 
              @submit="getConsent()"
            ></dialogadminpolicy>
            <dialogconsentetaxstatus
            :show="opendialogconsentetaxstatus"
            @close="opendialogconsentetaxstatus = false"
            >
            </dialogconsentetaxstatus>
            <dialogpasswordexpired
            :show="opendialogpasswordexpired"
            @close="opendialogpasswordexpired = false, get_log_password_expired()"
            :data="list_password_expired"
            :listcount="listcount"
            >
            </dialogpasswordexpired>
            <dialogtwofactorauthen
            :show="opendialogtwofactorauthen"
            @close="opendialogtwofactorauthen = false, get_two_factor_authen()"
            @closedialog="opendialogtwofactorauthen = false"
            :data="list_two_factor_authen"
            >
            </dialogtwofactorauthen>
          </v-card-text>
        </v-card>
      </v-card>
    </v-content>
  </v-app>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import gbfGenerate from "../globalFunctions/generateAuthorize";
import onebox_admin_toolbar from "../components/layout/layout-admin-toolbar";
const dialogadminpolicy = () => import("../components/optional/dialog-adminpolicy.vue");
const dialogcensentlog = () => import("../components/optional/dialog-consent-log.vue");
const dialogconsentetaxstatus = () => import("../components/optional/dialog-consent-etax-status.vue");
const dialogpasswordexpired = () => import("../components/optional/dialog-passwordexpired");
const dialogtwofactorauthen = () => import("../components/optional/dialog-two-factor-authen.vue");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  components: {
    onebox_admin_toolbar,
    dialogadminpolicy,
    dialogcensentlog,
    dialogconsentetaxstatus,
    dialogpasswordexpired,
    dialogtwofactorauthen,
  },
  data: function () {
    return {
      list_two_factor_authen: [],
      feature_two_factor_authen: true,
      opendialogtwofactorauthen: false,
      feature_password_expired: true,
      list_password_expired:[],
      listcount:0,
      expired_day:'',
      opendialogpasswordexpired: false,
      opendialogconsentetaxstatus: false,
      opendialogcensentlog : false,
      opendialogadminpolicy: false,
      listconsent: [],
      listconsent_etax_log:[],
      name_current_status:"",
      selectedpolicy: {},
      loaddataprogresstable: false,
      loaddataprogress: true,
      size: 20,
      page: 1,
      listsize: [10, 20, 50, 100],
      header: [
        {
          text: "#",
          align: "center",
          value: "type",
          width: "7%",
          sortable: false,
        },
        {
          // text: "admin.systemconsent",
          text: "admin.systembusiness",
          align: "",
          value: "consent_header",
          width: "80%",
          sortable: false,
        },        
        {
          text: "admin.status",
          align: "",
          value: "status",
          width: "13%",
          sortable: false,
        },        
      ],
    };
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "service",
      "color",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = this.listconsent.length;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    paginatedData() {
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.listconsent.slice(start, start + this.size);
    },
    headerTable() {
      return (
        "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;"
      );
    },
    headerPage() {
      return (
        "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600; line-height: 32px;"
      );
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
  },
  methods: {
    // ตั้งค่า two-factor-authen
    setting_two_factor_authen(){
      this.opendialogtwofactorauthen = true;

    },
    // ตั้งการรีเซ็ตรหัสผ่าน
    setting_reset_password(){
      this.opendialogpasswordexpired = true;
    },
    consent_etax_status(){
      this.opendialogconsentetaxstatus = true;
    },
    adminpolicy(item) {
      this.opendialogadminpolicy = true;
      this.selectedpolicy = item;
    },
    async getConsent() {
      let payload = {
        account_id: this.dataAccountId,
        business_id: this.dataAccountActive.business_info.business_id,
        one_biz_id: this.dataAccountActive.business_info.id,
      };
      console.log("payload checkStatusConsent", payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post( process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/get_accept_system", payload, {
          headers: { Authorization: auth.code },}
        )
        .then((res) => {
          if (res.data.status == "OK") {
            console.log("เข้านี่จ้าา", res.data);            
            this.listconsent = res.data.data;
          } else {
            Toast.fire({
              icon: "error",
              title: this.$t("toast.cannotconnectonebox"),
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
          this.loading = false;
        });
    },
    btnConsent(){
      this.opendialogcensentlog = true;

    },
    async get_log_consent_etax(){
      let payload = {
        business_id: this.dataAccountActive.business_info.business_id,
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post( process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/v1/get_accept_inbox_log", payload, {
          headers: { Authorization: auth.code },}
        )
        .then((res) => {
          if (res.data.status == "OK") {
            console.log("เข้า get_log_consent_etax", res.data);            
             this.listconsent_etax_log = res.data;
             console.log("listconsent_etax_log",this.listconsent_etax_log);
             this.name_current_status = res.data.current_status;
          } else {
            Toast.fire({
              icon: "error",
              title: this.$t("toast.cannotconnectonebox"),
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
          this.loading = false;
        });

    },
    // ตั้งการรีเซ็ตรหัสผ่าน
    async get_log_password_expired(){
      // console.log("เข้า get_log_password_expired");
      let payload = {
        business_id: this.dataAccountActive.business_info.business_id,
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post( process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/business/get/password_expired", payload, {
          headers: { Authorization: auth.code },}
        )
        .then((res) => {
          if (res.data.status == "OK") {
            this.list_password_expired = res.data;
            this.expired_day = res.data.current_password_expired;
            this.listcount = res.data.history_log.length
            // console.log("list_password_expired",this.list_password_expired);
            // console.log("expired_day",this.expired_day);
            // console.log("listcount",this.listcount);
          } 
          else if (res.data.errorCode === 'ER500'){
            // ถ้าไม่ได้เปิด feature password expired
            this.feature_password_expired = false;
            // console.log("ไม่ได้เปิด feature password expired");
            // Toast.fire({
            //   icon: "error",
            //   title: res.data.errorCode + ': ' + res.data.errorMessage,
            // });
          }else{
            Toast.fire({
              icon: "error",
              title: res.data.errorCode + ': ' + res.data.errorMessage,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
    async get_two_factor_authen(){
      // console.log("Get two factor");
      let payload = {
        business_id: this.dataAccountActive.business_info.business_id,
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post( process.env.VUE_APP_SERVICE_AUTHORIZE_API + "api/business/get/two_factor_authen", payload, {
          headers: { Authorization: auth.code },}
        )
        .then((res) => {
          console.log("Get two factor Success",res);
          if (res.data.status == "OK") {
            this.list_two_factor_authen = res.data;
            // console.log("list_two_factor_authen",this.list_two_factor_authen);
          } 
          else if (res.data.errorCode === 'ER500'){
            // console.log("ไม่ได้เปิด feature_two_factor_authen");
            this.feature_two_factor_authen = false;
            // Toast.fire({
            //   icon: "error",
            //   title: res.data.errorCode + ': ' + res.data.errorMessage,
            // });
          }else{
            Toast.fire({
              icon: "error",
              title: res.data.errorCode + ': ' + res.data.errorMessage,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    }
  },
  mounted() {
    //Get two factor authen
    this.get_two_factor_authen();
    // ความยินยอม
    this.getConsent();
    this.loaddataprogress = false;
    // log การใช้งาน การเลือกรับเอกสารอิเล็กทรอนิกส์
    this.get_log_consent_etax();
    // ตั้งการรีเซ็ตรหัสผ่าน
    this.get_log_password_expired();
  },
};
</script>
<style>
#thiscontainersetemailadmin {
  height: calc(101vh - 180px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
</style>

